import img01 from "../assets/images/olympoUrbano/ESTANDAR/salon-pasajeros---acabado-techo.jpg"
// import img02 from "../assets/images/olympoUrbano/img02.jpg"
import img02 from "../assets/images/olympoUrbano/ESTANDAR/conduccion---panel-de-control.jpg"
// import img03 from "../assets/images/olympoUrbano/img03.jpg"
// import img04 from "../assets/images/olympoUrbano/img04.jpg"
// import img04 from "../assets/images/olympoUrbano/ESTANDAR/exterior-2.jpg"
// import img05 from "../assets/images/olympoUrbano/img05.jpg"
import img05 from "../assets/images/olympoUrbano/ESTANDAR/exterior-3.jpg"
// import img06 from "../assets/images/olympoUrbano/img06.jpg"
// import img06 from "../assets/images/olympoUrbano/ESTANDAR/conduccion---sistema-de-camaras.jpg"
// import img07 from "../assets/images/olympoUrbano/img07.jpg"
// import img08 from "../assets/images/olympoUrbano/img08.jpg"
import img08 from "../assets/images/olympoUrbano/OPCIONALES/asientos-tapizados.jpg"
import img09 from "../assets/images/olympoUrbano/ESTANDAR/cabina-de-conductor---cupula.jpg"
import img10 from "../assets/images/olympoUrbano/ESTANDAR/cabina-de-conductor---tablero-de-control.jpg"
// import img11 from "../assets/images/olympoUrbano/ESTANDAR/conducción---espejos-retrovisores.jpg"
import img12 from "../assets/images/olympoUrbano/ESTANDAR/exterior-1.jpg"
import img13 from "../assets/images/olympoUrbano/ESTANDAR/exterior-4.jpg"
import img14 from "../assets/images/olympoUrbano/ESTANDAR/salon-pasajeros---acabado-lateral-1.jpg"
import img15 from "../assets/images/olympoUrbano/ESTANDAR/salon-pasajeros---asientos-de-plastico.jpg"
import img16 from "../assets/images/olympoUrbano/OPCIONALES/ventilador.jpg"
import img18 from "../assets/images/OLYMPO325/ESTANDAR/conduccion---accionamiento-de-puerta-de-servicio.jpg"
export const OlympoBlock1 = [
  {
    title: `ACABADO TECHO`,
    text: ` Impermeable, de facil limpieza y antinflamable.`,
    img: img01,
  },
  {
    title: `ACABADO LATERALES`,
    text: `Impermeable, de facil limpieza y antinflamable.<br /> Perfilado de marcos de ventanas con PVC DECORATIVO de brillo natural.`,
    img: img14,
  },
  {
    title: `ASIENTOS`,
    text: `Asientos fijos inyectados de plástico con tapetes individuales.`,
    img: img15,
  }
]
export const OlympoBlock2 = [
  {
    title: `TABLERO DE CONTROL`,
    text: `Diseño AERODINÁMICO elaborado en fibra de vidrio con colores elegantes.`,
    img: img10,
  },
  {
    title: `CÚPULA`,
    text: `Elaborado en fibra de vidrio con lineas estelizadas decorativas y contornos definidos.`,
    img: img09,
  }
]
export const OlympoBlock3 = [
  {
    title: `ACCIONAMIENTO PUERTA SERVICIO`,
    text: ` Accionado automático con motores eléctricos o neumático.`,
    img: img18,
  },
  {
    title: `PANEL DE CONTROL`,
    text: `Espacioso`,
    img: img02,
  }
]
export const OlympoBlock4 = [
  {
    title: `ISO FRONTAL`,
    text: ` ELEGANTE E INNOVADOR`,
    img: img12,
  },
  {
    title: `ISO POSTERIOR`,
    text: ` ELEGANTE`,
    img: img05,
  },
  {
    title: `FRONTAL`,
    text: `Mascara, Parachoque y Trampilla.`,
    img: img13,
  }
]
export const OlympoBlock5 = [
  {
    title: `ASIENTOS TAPIZADOS`,
    text: ` Asiento fijo tapizado en tela automotriz.<br /> Espumas inyectadas en poliuretano flexible. `,
    img: img08,
  },
  {
    title: `VENTILADOR`,
    text: `Refresca el ambiente del conductor.`,
    img: img16,
  }
]
